import {
  Squares2X2Icon,
  QueueListIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentCheckIcon,
  TicketIcon,
  NewspaperIcon,
  CheckBadgeIcon,
  DevicePhoneMobileIcon,
  CurrencyPoundIcon,
} from '@heroicons/vue/24/outline'

const routes = [
  {
    label: 'Dashboard',
    link: '/',
    icon: Squares2X2Icon,
  },
  {
    label: 'Agreements',
    link: '/agreements',
    icon: CheckBadgeIcon,
  },
  {
    label: 'Bookings & Earnings',
    link: '/bookings-earnings',
    icon: QueueListIcon,
  },
  {
    label: 'Payouts',
    link: '/payouts',
    icon: CurrencyPoundIcon,
  },
  {
    label: 'Plans & Invoices',
    link: '/plans-invoices',
    icon: DocumentDuplicateIcon,
  },
  {
    label: 'Vehicle & Documents',
    link: '/vehicle-documents',
    icon: ClipboardDocumentCheckIcon,
  },
  {
    label: 'Tickets & Support',
    link: '/tickets-support',
    icon: TicketIcon,
  },
  {
    label: 'Partner App',
    link: 'https://onelink.to/4q3kam',
    icon: DevicePhoneMobileIcon,
    external: true,
  },
  {
    label: 'News',
    link: '/news',
    icon: NewspaperIcon,
  },
]

export default routes
